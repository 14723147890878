import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ClickableTileWrapper from "@src/components/core-clickable-tile-wrapper"
import Image from "@src/components/core-image"
import Button from "./core-button"
const PromoTile = styled(ClickableTileWrapper)`
  flex: 0 0 300px;
  & > a.trigger:hover {
    border-radius: 12px;
  }
  & > .promo-tile {
    width: 300px;
    height: 100%;
    padding: 16px;
    border-radius: 16px;
    font-family: "system-ui";
    display: grid;
    grid-template-rows: 30px 1fr 200px auto 30px;
    & > .first-line {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    & > .second-line {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0px;
      margin: 0;
      color: #ffffff;
    }
    & > .third-line {
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #ffffff;
    }
    & .lazyload-wrapper {
      display: flex;
      justify-content: center;
    }
    &.question {
      background: #b7d7cd; // Natasha Design
      & > .first-line {
        color: #3a6968;
      }
      & > .second-line,
      & > .third-line {
        color: #26383b;
      }
    }
    &.discount {
      background: #497e7a; // Natasha Design
      & > .first-line {
        color: #b7d7cd;
      }
    }
    &.customize {
      background: #26383b; // Natasha Design
      & > .first-line {
        color: #8bbbaf;
      }
    }
  }
`

export const TourPromoTile = ({
  variant,
  to,
  title,
  firstLine,
  secondLine,
  image,
  thirdLine,
  buttonName,
}) => (
  <PromoTile key={variant} to={to} title={title} external>
    <div className={`promo-tile ${variant}`}>
      <p className="first-line">{firstLine}</p>
      <h2 className="second-line">{secondLine}</h2>
      <Image width={200} height={200} src={image} />
      <p className="third-line">{thirdLine}</p>
      <Button
        className="promo-action-button"
        color="outline-black"
        size="small"
        href={to}
        mobileSizeOnly
        external
      >
        {buttonName}
      </Button>
    </div>
  </PromoTile>
)

TourPromoTile.propTypes = {
  variant: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  image: PropTypes.string,
  thirdLine: PropTypes.string,
  buttonName: PropTypes.string,
}
export default TourPromoTile
