import React from "react"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import ToursLayout from "@src/layouts/tours"
import { ImagePropTypes } from "@src/components/core-image"
import { PageSeoPropTypes } from "@src/components/page-helmet"
import { TourDetailTilePropTypes } from "@src/components/content-tour-detail-tile"
import { orderByField } from "@src/utils/sorting"
import { tourPromoTileContent } from "@src/utils/constants/tour-promo-tile-content"
import TourPromoTile from "@src/components/content-tour-promo-tile"

const PageTemplate = ({
  pageContext: {
    data: { tenTours: data },
    skipForBottomSeo,
    replaceURLs,
    ...pageContext
  },
}) => {
  const {
    tourRegion: {
      name,
      slug,
      seo: pageSeo,
      seoTextTop,
      bottomSeo,
      faq,
      seoTextBottomNew,
    },
    tours: { nodes: tourData },
  } = data
  const {
    basePath: pageUri,
    pageNumber,
    pageCount,
    postsPerPage: toursPerPage,
    postsTotal: totalTours,
    postIds: toursOrder,
    regionId,
  } = pageContext
  const TOURS_LAYOUT_TOP_TEXT_DEFAULT = `Explore incredible adventure tours in ${name} below.`
  const textTop =
    pageNumber > 1
      ? null
      : seoTextTop
      ? seoTextTop
      : TOURS_LAYOUT_TOP_TEXT_DEFAULT
  GTM.dataLayerPushPageInfo({
    template: "tour-region",
  })
  return (
    <ToursLayout
      regionName={name}
      regionSlug={slug}
      pageName={`${name} Tours`}
      pageSeo={{ ...pageSeo, metaRobotsNoindex: "index" }}
      headerText={textTop}
      tourData={tourData?.sort(orderByField("id", toursOrder))}
      toursParentUri={pageUri}
      toursPageNumber={pageNumber}
      toursPageCount={pageCount}
      toursPerPage={toursPerPage}
      totalTours={totalTours}
      regionId={regionId}
      faqs={faq}
      seoTextBottomNew={seoTextBottomNew}
      replaceURLs={replaceURLs}
      tourListPromos={{
        5: index => <TourPromoTile key={index} {...tourPromoTileContent[0]} />,
        9: index => <TourPromoTile key={index} {...tourPromoTileContent[1]} />,
        16: index => <TourPromoTile key={index} {...tourPromoTileContent[2]} />,
      }}
      bottomSeoData={bottomSeo}
      skipForBottomSeo={skipForBottomSeo}
    />
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    postIds: PropTypes.arrayOf(PropTypes.number),
    regionId: PropTypes.number,
    skipForBottomSeo: PropTypes.arrayOf(PropTypes.string),
    replaceURLs: PropTypes.object,
    data: PropTypes.shape({
      tenTours: PropTypes.shape({
        tourRegion: PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
          uri: PropTypes.string,
          seo: PropTypes.shape({ ...PageSeoPropTypes }),
          seoTextTop: PropTypes.string,
          faq: PropTypes.shape({
            title: PropTypes.string,
            faqs: PropTypes.arrayOf(
              PropTypes.shape({
                question: PropTypes.string,
                answer: PropTypes.string,
              })
            ),
          }),
          seoTextBottomNew: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.string,
              title: PropTypes.string,
            })
          ),
          headerImage: PropTypes.shape({
            feature: PropTypes.shape({ ...ImagePropTypes }),
          }),
        }),
        tours: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              ...TourDetailTilePropTypes,
            })
          ),
        }),
      }),
    }),
  }),
}

export default PageTemplate
