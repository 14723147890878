export const tourPromoTileContent = [
  {
    variant: "question",
    to: "/contact/",
    title: "Go to contact us page",
    firstLine: "We are here to help",
    secondLine: "Ask us a question!",
    image: "/images/promo-question-image.svg",
    thirdLine: `We promise you will always have a real person to help plan your next
    vacation. A team member is always just a call or a click away.`,
    buttonName: "Chat With Us",
  },
  {
    variant: "discount",
    to: "/contact/",
    title: "Go to contact us page",
    firstLine: "Tour group discount",
    secondLine: "Travelling with a group of 8 or more?",
    image: "/images/promo-discount-image.svg",
    thirdLine: `Contact us to get a group discount and pick your next adventure at a
    lower price.`,
    buttonName: "Contact Us",
  },
  {
    variant: "customize",
    to: "/contact/",
    title: "Go to contact us page",
    firstLine: "Customize your trip",
    secondLine: "Your trip. Your way.",
    image: "/images/promo-customize-image.svg",
    thirdLine: `All private tours can be customized, so you can get the tour you've been dreaming about.`,
    buttonName: "Customize Now",
  },
]
